<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="7"
      >
        <b-card
          class="mb-base"
        >

          <b-row align-h="end">
            <b-col cols="10">
              {{ $t('User Details') }}
            </b-col>
            <b-col cols="2">
              <vx-badge
                class="mb-1"
                :color="user.status==='Active'?'light-success':user.status==='Blocked'?'light-danger':'light-warning'"
              >
                {{ $t(user.status) }}
              </vx-badge>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-row>
                <b-col cols="4">
                  <div class="img-container mb-2">
                    <b-avatar
                      size="70px"
                      :src="require('@/assets/images/portrait/small/avatar-s-20.jpg')"
                    />
                  </div>
                </b-col>
                <b-col cols="8">
                  <table>
                    <tr>
                      <td><h3>{{ user.first_name +' '+ user.last_name }}</h3></td>
                    </tr>
                    <tr>
                      <td><h5>{{ user.email }}</h5></td>
                    </tr>
                    <tr>
                      <td><h5>{{ user.mobile }}</h5></td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-row>
                <b-col
                  v-for="(value, indexrole) in user.role"
                  :key="indexrole"
                  cols="6"
                >
                  <vx-badge
                    class="mb-1"
                    color="light-warning"
                  >
                    <span>{{ $t(value.name) }}<br></span>
                  </vx-badge>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr>
          <b-row align-h="end">
            <b-col
              cols="3"
              lg="3"
              md="2"
            >
              <vx-badge
                color="light-success"
                class="mb-1"
              >
                {{ $t(user.created_from) }}
              </vx-badge>

            </b-col>
            <b-col
              cols="3"
              lg="3"
              md="2"
            >
              <vx-badge
                color="light-warning"
              >
                {{ $t(user.updated_from) }}
              </vx-badge>

            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        xl="5"
      >
        <b-row>
          <b-col
            cols="6"
            lg="6"
            md="3"
          >
            <b-card
              class="mb-3"
              justify="center"
              align="center"
            >
              <b-avatar
                class="mb-1"
                size="lg"
                variant="light-success"
              >
                <fa-icon
                  size="2"
                  icon="arrow-down"
                  pack="fas"
                />
              </b-avatar>

              <h5 class="mb-1 font-weight-bolder">
                <vx-badge
                  color="light-success"
                  icon-color-bg="success"
                  icon-color="null"
                >
                  {{ user.income }}
                </vx-badge>
              </h5>

              <span>{{ $t('Total Income') }}</span>
            </b-card>
          </b-col>
          <b-col
            cols="6"
            lg="6"
            md="3"
            xs="2"
          >
            <b-card
              class="mb-3"
              justify="center"
              align="center"
            >
              <b-avatar
                class="mb-1"
                size="lg"
                variant="light-danger"
              >
                <fa-icon
                  size="2"
                  icon="arrow-up"
                  pack="fas"
                />
              </b-avatar>

              <h5 class="mb-1 font-weight-bolder">
                <vx-badge
                  color="light-danger"
                  icon-color-bg="danger"
                  icon-color="null"
                >
                  {{ user.outcome }}
                </vx-badge>
              </h5>

              <span>{{ $t('Total Outcome') }}</span>
            </b-card>
          </b-col>
          <b-col
            cols="6"
            lg="6"
            md="3"
            xs="2"
          >
            <b-card
              class="mb-3"
              justify="center"
              align="center"
            >
              <b-avatar
                class="mb-1"
                size="lg"
                variant="light-warning"
              >
                <fa-icon
                  size="2"
                  icon="exchange-alt"
                  pack="fas"
                />
              </b-avatar>

              <h5 class="mb-1 font-weight-bolder">
                <vx-badge
                  color="light-warning"
                  icon-color-bg="warning"
                  icon-color="null"
                >
                  {{ user.balance }}
                </vx-badge>
              </h5>
              <span>{{ $t('Balance') }}</span>
            </b-card>
          </b-col>
          <b-col
            cols="6"
            lg="6"
            md="3"
          >
            <b-card
              class="mb-3"
              justify="center"
              align="center"
            >
              <b-avatar
                class="mb-1"
                size="lg"
                variant="light-primary"
              >
                <fa-icon
                  size="2"
                  icon="calendar-plus"
                  pack="fas"
                />
              </b-avatar>

              <h5 class="mb-1 font-weight-bolder">
                <vx-badge
                  color="light-primary"
                  icon-color-bg="primary"
                  icon-color="null"
                >
                  {{ user.last_transaction_from }}
                </vx-badge>
              </h5>
              <span>{{ $t('Last Trans') }}</span>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-card
          v-if="user.logins"
        >
          <vs-table
            :no-data-text="$t('No data Available')"
            stripe
            :data="user.logins"
          >
            <template slot="header">
              <h3 class="mb-5 mt-1">
                {{ $t('logins') }}
              </h3>
            </template>
            <template slot="thead">
              <vs-th>{{ $t('Browser') }}</vs-th>
              <vs-th>{{ $t('Device') }}</vs-th>
              <vs-th>{{ $t('Device Type') }}</vs-th>
              <vs-th>{{ $t('Login Type') }}</vs-th>
              <vs-th>{{ $t('platform') }}</vs-th>
              <vs-th>{{ $t('Logged in At') }}</vs-th>
              <vs-th>{{ $t('IP') }}</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr
                v-for="(tr, indextr) in data"
                :key="indextr"
              >
                <vs-td :data="tr.browser">
                  <img
                    v-if="tr.browser_image"
                    width="20px"
                    :src="require(`@/assets/images/browsers/${tr.browser_image}.png`)"
                  >
                </vs-td>
                <vs-td :data="tr.device">
                  {{ tr.device }}
                </vs-td>
                <vs-td :data="tr.device_type">
                  <fa-icon
                    color="primary"
                    pack="fas"
                    :icon="tr.device_type_icon"
                  />
                </vs-td>
                <vs-td :data="tr.login_type">
                  {{ $t(tr.login_type) }}
                </vs-td>
                <vs-td :data="tr.platform">
                  <fa-icon
                    color="primary"
                    pack="fab"
                    :icon="tr.platform_icon"
                  />
                </vs-td>
                <vs-td :data="tr.created_at">
                  {{ $t(tr.created_at) }}
                </vs-td>
                <vs-td :data="tr.ip">
                  {{ tr.ip }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-card
          v-if="user.devices"
        >
          <vs-table
            stripe
            :data="user.devices"
          >
            <template slot="header">
              <h3 class="mb-5 mt-1">
                {{ $t('Devices') }}
              </h3>
            </template>
            <template slot="thead">
              <vs-th>{{ $t('Browser') }}</vs-th>
              <vs-th>{{ $t('Device') }}</vs-th>
              <vs-th>{{ $t('Device Type') }}</vs-th>
              <vs-th>{{ $t('Login Type') }}</vs-th>
              <vs-th>{{ $t('platform') }}</vs-th>
              <vs-th>{{ $t('Logged in At') }}</vs-th>
              <vs-th>{{ $t('IP') }}</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr
                v-for="(tr, indextr) in data"
                :key="indextr"
              >
                <vs-td :data="tr.browser">
                  <img
                    v-if="tr.browser_image"
                    width="20px"
                    :src="require(`@/assets/images/browsers/${tr.browser_image}.png`)"
                  >
                </vs-td>
                <vs-td :data="tr.device">
                  {{ tr.device }}
                </vs-td>
                <vs-td :data="tr.device_type">
                  <fa-icon
                    color="primary"
                    pack="fas"
                    :icon="tr.device_type_icon"
                  />
                </vs-td>
                <vs-td :data="tr.login_type">
                  {{ tr.login_type }}
                </vs-td>
                <vs-td :data="tr.platform">
                  <fa-icon
                    color="primary"
                    pack="fab"
                    :icon="tr.platform_icon"
                  />
                </vs-td>
                <vs-td :data="tr.created_at">
                  {{ tr.created_at }}
                </vs-td>
                <vs-td :data="tr.ip">
                  {{ tr.ip }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  /* eslint-disable */
  data:()=>({
  }),
  methods:{
  },
  computed:{
    user(){
      return this.$store.getters['user/user']
    }
  },
  mounted(){
  },
  created() {

      this.$store.dispatch('user/GetUserData',this.$route.params.userId)
  }
}
</script>
